import {getWrappedComponent} from '../../../appConfig';
import {withStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import {SliderGallery} from '../../../sliderGallery/components/SliderGallery/SliderGallery';
import {sliderGlobalPropsStrategy} from '../../../sliderGallery/sliderGlobalStrategy';

export default getWrappedComponent({
  Component: SliderGallery,
  withStyleStrategy: withStyles,
  globalPropsStrategy: sliderGlobalPropsStrategy,
  sliderGallery: true,
});
